/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import clsx from 'clsx';
import React from 'react';

import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {
  selectEmailBannerFlag,
  selectLoader,
  selectShowCookiesBanner,
} from '../store/selectors/global';
import Header from './header';
import Footer from './footer';
import Loader from './loader';
import BannerRefferalHeader from './banner-refferal-header';
import CookieBannerCmp from './cookie-banner';
import BannerEmailCheck from './banner-email-check';
import useWeglot from '../hooks/useWeglot';
import { selectRefferalInfo } from '../store/selectors/entities';
import { isClient } from '../helpers/utils';
import { useSift } from '../../config/sift';

const stateSelector = createStructuredSelector({
  isLoading: selectLoader,
  refferalInfo: selectRefferalInfo,
  isWrongEmail: selectEmailBannerFlag,
  showCookiesBanner: selectShowCookiesBanner,
});

const Layout = ({
  children,
  pageClassName,
  pageWrapperClassName,
  fluid,
  hideHeader,
  hideFooter,
  showLogOutOnly,
  hideNav,
}) => {
  const { isLoading, refferalInfo, isWrongEmail, showCookiesBanner } = useSelector(stateSelector);
  const showBannerHeader = refferalInfo && refferalInfo.showBannerHeader;
  const bannerClassName = showBannerHeader ? 'withBannerHeader' : '';

  // useWeglot();
  useSift();

  const showHeader = !hideHeader;
  const showFooter = !hideFooter;

  return (
    <div className={clsx(pageClassName, 'layout')}>
      {showHeader && <Header showLogOutOnly={showLogOutOnly} hideNav={hideNav} />}
      <div className={clsx('globalWrapper', pageWrapperClassName, bannerClassName)}>
        <div className={fluid ? null : 'container'}>
          <main>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {showBannerHeader && <BannerRefferalHeader {...refferalInfo} />}
            {isWrongEmail && <BannerEmailCheck />}
            {children}
            {isClient && <Loader isLoading={isLoading} isSmall={false} />}
          </main>
        </div>
      </div>
      {showFooter && <Footer />}
      <NotificationContainer />
      {showCookiesBanner && <CookieBannerCmp />}
    </div>
  );
};

Layout.defaultProps = {
  pageClassName: null,
  fluid: null,
  pageWrapperClassName: null,
  hideHeader: false,
  hideFooter: false,
  showLogOutOnly: false,
  hideNav: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageClassName: PropTypes.string,
  pageWrapperClassName: PropTypes.string,
  fluid: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  showLogOutOnly: PropTypes.bool,
  hideNav: PropTypes.bool,
};

export default Layout;
