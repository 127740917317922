import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './loader.module.scss';

const Loader = ({ isLoading, isSmall }) => isLoading ? (
    <div className={clsx(styles.wrapper, isSmall && styles.isSmall)}>
      <div className={styles.loader} />
    </div>
  ) : (
    <div />
  );

Loader.propTypes = { isLoading: PropTypes.bool.isRequired, isSmall: PropTypes.bool.isRequired };

export default Loader;
