import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { countries } from 'countries-list';
import { Link } from 'gatsby-plugin-intl';
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-world-flags';
import { pageLinks } from '../../helpers/navigation';
import { getCountryFilterParam } from '../../helpers/instanceHandler';
import { selectUserCountry } from '../../store/selectors/global';
import styles from './region-select.module.scss';
import { setUserStartCountry } from '../../store/actions';

const getFlag = (name) => <Flag code={name} width="28" height="14" />;

const RegionSelect = ({ invertDark }) => {
  const linkState = { modal: true };
  const userCountry = useSelector(selectUserCountry);
  const dispatch = useDispatch();
  const startCountry = process.env.GATSBY_INSTANCE_COUNTRY; // for shop.  UAE (AE) | for ibi. US

  useEffect(() => {
    const country = userCountry || startCountry
    dispatch(setUserStartCountry(country));
  }, [])

  const instanceCountries = getCountryFilterParam() || '';

  const singleCountryInstance = instanceCountries.length === 1;

  const countryCode = singleCountryInstance
    ? instanceCountries[0].toUpperCase()
    : userCountry || startCountry;

  const countryData = countries[countryCode] || {};
  const flag = countryCode && getFlag(countryCode);

  return (
    <div className={clsx(styles.inputSelectWrapper, singleCountryInstance && styles.disabled)}>
      <div className={styles.wrapper}>
        <Link to={pageLinks.regions} state={linkState} className={clsx(styles.link)}>
          <span className={styles.emoji}>{flag}</span>
          <span
            className={clsx(styles.code, { [styles.invertColor]: invertDark }, 'dont-translate')}
          >
            {countryData.name || 'Select country'}
          </span>
        </Link>
      </div>
    </div>
  );
};

RegionSelect.defaultProps = { invertDark: false };
RegionSelect.propTypes = { invertDark: PropTypes.bool };

export default RegionSelect;
