import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { socialLinks } from './constants';

import styles from './banner-refferal-header.module.scss';

const BannerRefferalHeader = ({ customerId, firstName, lastName, email }) => {
  const id = customerId;
  const name = `${firstName} ${lastName}`;

  return (
    <div className={styles.bannerWrapper}>
      <section className={styles.userInfo}>
        <div className={styles.userTitle}>
          <span className={styles.userInfoLabel}>Your refere:</span>
          <span className={styles.userName}>{name}</span>
        </div>

        <span className={styles.verticalDivider} />

        <div className={styles.userInfoSection}>
          <span className={styles.userInfoLabel}>ID:</span>
          <span className={styles.userInfoText}>{id}</span>
        </div>

        <div className={styles.userInfoSection}>
          <span className={styles.userInfoLabel}>Email:</span>
          <span className={styles.userInfoText}>
            <a href={email} target="_blank" rel="noopener noreferrer">
              {email}
            </a>
          </span>
        </div>

        <div className={styles.userInfoSection}>
          <span className={clsx(styles.userInfoLabel, styles.black)}>Social:</span>

          {socialLinks.map((icon) => (
            <span key={icon.name} className={styles.userInfoIcon}>
              <a href={icon.url} title={icon.name} target="_blank" rel="noopener noreferrer">
                {icon.src}
              </a>
            </span>
          ))}
        </div>
      </section>
    </div>
  );
};

BannerRefferalHeader.propTypes = {
  customerId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default BannerRefferalHeader;
