import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './button.module.scss';
import checkLoader from '../../hooks/checkLoaderButton';

const Button = ({
  fullWidth,
  className,
  type,
  variant,
  size,
  color,
  disabled,
  withArrow,
  defaultWidth,
  loaderType,
  children,
  component: Component,
  ...props
}) => {
  const showLoader = loaderType && checkLoader(loaderType);

  const classes = [
    styles.btnBase,
    styles.btn,
    styles[variant],
    styles[size],
    styles[color],
    { [styles.withArrow]: withArrow },
    { [styles.fullWidth]: fullWidth },
    { [styles.defaultWidth]: defaultWidth },
    { [styles.withLoader]: showLoader },
  ];

  const classname = clsx(classes, className);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <Component disabled={disabled || showLoader} type={type} className={classname} {...props}>
      {showLoader ? (
        <div className="ld ld-hourglass ld-spin-fast" style={{ fontSize: 37, color: '#b55ef6' }} />
      ) : (
        children
      )}
    </Component>
  );
};

Button.defaultProps = {
  component: 'button',
  type: 'button',
  className: '',
  color: '',
  variant: 'contained',
  size: 'medium',
  fullWidth: false,
  disabled: false,
  withArrow: false,
  defaultWidth: false,
};

Button.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  type: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  withArrow: PropTypes.bool,
  defaultWidth: PropTypes.bool,
  loaderType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;
